.profile-header {
  display: flex;
  justify-content: center;
}

.profile-header-text {
  width: 70%;
  height: 70px;
  border-bottom: 1px solid #e7e6e9;
  display: flex;
}

.profile-header-text > p {
  font-size: 1.5rem;
  margin: 20px 0 20px 14px;
}

.profile-body {
  display: flex;
  justify-content: center;
}

.profile-container {
  display: flex;
  width: 70%;
}

.profile-container-menu {
  width: 22%;
  border-right: 1px solid #e7e6e9;
  min-height: 600px;
}

.profile-container-menu > ul {
  margin-top: 0;
  padding-left: 0;
  text-align: start;
}
.profile__general-info__form__fields > .text-field {
  width: 100%;
}

.profile-container-menu > ul > li {
  list-style-type: none;
  height: 50px;
  padding-top: 10px;
  display: flex;
  align-items: center;
}

.profile-container-menu > ul > li > a {
  font-size: 1.3rem;
  font-weight: 700;
  position: relative;
  text-decoration: none;
  padding: 0px 15px;
  display: block;
  height: 100%;
  width: 100%;
}

.profile-container-menu > ul > li > a:active {
  color: #551a8b;
}

.profile-container-menu > ul > .active {
  background-color: #457ec1;
}

.profile-container-menu > ul > .active > a {
  color: white;
}

.profile-container-menu > ul > .active > a:active {
  color: white !important;
}

.tab-list {
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.profile-settings-form {
  padding-left: 6%;
  padding-top: 40px;
}

.profile__general-info__form {
  text-align: start;
  padding: 35px 120px;
  border-radius: 8px;
  position: relative;
  height: 100%;
}

.reset-password-dialog {
  .MuiDialogTitle-root {
    border-bottom: 1px solid #9199e7;
    font-size: 20px;
    p {
      margin: 0px;
    }
  }

  .MuiDialogContent-root {
    width: 380px;
    padding: 16px 24px !important;
  }

  .MuiDialogActions-root {
    padding: 8px 24px;
  }
}

.profile__general-info__form__fields {
  position: relative;
}

.profile__general-info__form__fields > .text-field {
  margin-top: 15px;
}

.validation-error {
  margin-top: unset;
  text-align: center;
  font-size: 0.9rem;
}

.general-profile__select {
  margin-top: 15px !important;
  width: inherit;
}
/* Style for ChangeProfilePhoto block*/

.react-crop {
  .ReactCrop__rule-of-thirds-vt,
  .ReactCrop__rule-of-thirds-hz {
    ::after,
    ::before {
      background: red;
    }
  }
}

.drag-zone {
  min-height: 100px;
  overflow: hidden;
  text-align: center;
  width: 470px;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px dashed var(--stroke);
  border-radius: 10px;
  gap: 40px;

  p {
    color: var(--text-2);
  }

  span {
    color: var(--accent-dark);
    text-decoration: underline;
  }
}

.ReactCrop {
  height: 90%;
  width: 90%;
}

.upload-photo-dialog__title {
  top: 30px;
  left: 40px;
  font-size: 22px;
  margin: 0px;
  color: var(--text-2);
}

.upload-photo-dialog__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 32px 0px 40px;

  img {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}

.drag-zone svg {
  font-size: 60px;
}
.change-photo-cmps {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 20px;
}
.change-photo-cmps .bg-image-modal {
  background: var(--user-profile-avatar-hover);
  border-radius: 50px;
  color: black;
  display: block;
  width: 100px;
  height: 100px;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.9em;
}
.change-photo-cmps .bg-image-modal:hover {
  text-decoration: none;
}
.change-photo-cmps .upload-action:hover .bg-image-modal {
  visibility: visible;
  cursor: pointer;
}
.change-photo-cmps .user-image-change {
  z-index: 1;
  cursor: pointer;
}
.change-photo-cmps .edit-image {
  color: white;
  border: 1px solid white;
  border-radius: 6px;
  margin-top: 40%;
  padding: 8px 10px;
  margin: 30% 15% 0 15%;
  z-index: 2;
  text-align: center;
}
.change-photo-cmps .text-block {
  text-align: left;
  padding-left: 17px;
  padding-right: 15px;
}
.change-photo-cmps .text-block .title {
  font-weight: normal;
  font-size: 1.3rem;
  color: #444c63;
  padding: 5px 0;
}

.makeStyles-paper-6:active {
  border: none;
}

.makeStyles-paper-6 section {
  text-align: center;
}

#transition-modal-title {
  border-bottom: 1px solid var(--stroke);
  background: var(--background-1);
  color: var(--text-2);
  font-size: 1.1rem;
  margin-bottom: 20px;
  padding: 5px 0;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.makeStyles-paper-6 .row {
  display: flex;
  justify-content: flex-end;
}

.makeStyles-paper-6 .row button {
  padding: 10px 12px;
  outline: 0;
  border: none;
  margin-right: 50px;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  background: #457ec1 !important;
  border-radius: 3px !important;
  color: white !important;
  padding: 6px 8px;
  font-size: 0.9rem !important;
  font-weight: 500;
  line-height: 17px;
  text-transform: none;
}

.makeStyles-paper-6 .row {
  margin-top: 30px;
}

.cancel-upload {
  color: #4a59ac;
  font-weight: 500;
  font-size: 0.9rem;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  line-height: 1.42857143;
  border-radius: 0;
  text-decoration: none;
}

.notifications-group {
  margin-bottom: 30px;
  color: var(--text-2);

  .notifications-description {
    margin-left: 39px;
    font-size: 14px;
  }

  .notifications-header {
    margin: 0px;
    color: var(--text-1);
    font-weight: 500;
  }

  .tb-radio-button {
    height: fit-content;
  }

  .notifications-secondary-header {
    margin-bottom: unset;
    font-weight: 500;
  }

  .primary-row {
    p {
      font-size: 16px !important;
    }
  }

  .notifications-select-row {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
    }

    p {
      font-size: 14px;
      user-select: none;
    }
  }
}

.secondary-block {
  margin-left: 39px;
  margin-top: -29px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  p {
    line-height: 14px;
  }
}

.profile__title {
  color: var(--button-text-hover);
  line-height: 36px;
  font-size: 22px;
  margin-bottom: 40px;
}

.profile__input {
  margin: 0px 0px 10px 0px !important;
}

.profile__show-container {
  position: fixed;
  top: 120px;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.profile__show-right-side {
  background-color: var(--background-2);
  max-height: 82%;
  width: 970px;
  overflow: hidden;
  border-radius: 10px;
  min-height: 550px;
  height: fit-content;
  min-height: 580px;
}

.profile__info-edit {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.profile__left-side {
  position: relative;
  width: 220px;
  & div:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  & div:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .left-side__border {
    position: absolute;
    top: 80px;
    border-radius: 10px;
    border: 1px solid var(--stroke);
  }
}

.profile__left-side__option {
  width: 220px;
  padding: 10px 20px;
  cursor: pointer;
  p {
    margin: unset;
    text-align: start;
    font-size: 16px;
    line-height: 30px;
  }
}

.show-profile__container {
  padding: 35px 120px;
  display: flex;
  justify-content: space-between;
  position: relative;
  flex-wrap: wrap;
  overflow: hidden;

  .show-profile__block {
    flex: 1;
    max-width: 40%;

    .show-profile__value {
      margin-bottom: 14px;
      min-height: 80px;
      display: flex;
      flex-direction: column;
      color: var(--text-2);

      p {
        text-align: left;
      }
      .name {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
      }
      .value {
        font-size: 16px;
        font-weight: 300;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
