.settings-header {
  display: flex;
  justify-content: center;
}

.settings-header-text {
  width: 70%;
  height: 70px;
  border-bottom: 1px solid #e7e6e9;
  display: flex;
}

.settings-header-text > p {
  font-size: 1.5rem;
  margin: 20px 0 20px 14px;
}

.settings-body {
  display: flex;
  justify-content: center;
}

.settings-container {
  display: flex;
  width: 70%;
}

.settings-container-menu {
  width: 18%;
  border-right: 1px solid #e7e6e9;
  min-height: 600px;
}

.settings-container-menu > ul {
  margin-top: 0;
  padding-left: 0;
  text-align: start;
}

.tab-list {
  border-bottom: 1px solid #cccccc;
  padding-left: 0;
}

.tab-list-item {
  display: inline-block;
  list-style: none;
  margin-bottom: -1px;
  padding: 0.5rem 0.75rem;
}

.tab-list-active {
  background-color: white;
  border: solid #ccc;
  border-width: 1px 1px 0 1px;
}

.account-settings-form {
  padding-left: 6%;
  padding-top: 40px;
}

.settings__submit {
  background: #e1e8f0 !important;

  border: 1px solid #c8d2e6;
  border-radius: 4px;
}

.select-module-dialog__plans {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;

  p {
    color: var(--text-1);
  }

  .select-module-dialog__plan {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .select-module-dialog__plan-info {
    width: 100%;
    height: 60px;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid var(--stroke);
    display: flex;
    align-items: center;

    p {
      margin: 0px;
    }
  }
}

.select-module-dialog__modules {
  padding-bottom: 40px;
  border-bottom: 1px solid var(--stroke);

  p {
    color: var(--text-1);
  }

  .select-module-dialog__module {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;

    p {
      margin: 0px;
    }
  }
}

.subscription-modal {
  .MuiDialogContent-root,
  .subscription-modal-dialog__title {
    background-color: var(--background-1) !important;
  }
  .MuiPaper-root {
    border-radius: 20px !important;
    background-color: var(--background-1) !important;
    box-shadow: var(--box-shadow);
    color: var(--text-2);
  }
}

.settings__general-info__form {
  text-align: start;
  height: 90%;
}

.credentials-form {
  background-color: var(--background-2);
  width: 970px;
  border-radius: 10px;
  min-height: 580px;
  height: fit-content;
  max-height: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.staging-settings__checkbox {
  display: flex;
  align-items: color;
  align-items: center;
  gap: 10px;
  white-space: nowrap;

  p {
    font-size: 16px;
  }

  img {
    width: 20px !important;
    height: 20px !important;
  }
}

.credentials__email {
  width: 87.63%;
  height: 123px;
  background-color: var(--background-1);
  border-radius: 7px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  .credentials__email-info {
    text-align: left;

    .email-info__title {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.41px;
      color: var(--text-2);
      margin: unset;
    }
    .email-info__mail {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 300;
      line-height: 22px;
      letter-spacing: -0.41px;
      color: var(--text-2);
      margin: 11px 0;
    }
  }
  .email-info__submit-button {
    padding: 8px 24px;
  }
}

.credentials__password {
  width: 87.63%;
  min-height: 123px;
  background-color: var(--background-1);
  border-radius: 7px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;

  .credentials__password-info {
    text-align: left;

    .input-form-component {
      margin: unset;
      width: 270px;
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: -0.41px;
        margin: unset;
      }
    }

    .forgot-password-text {
      text-decoration: underline;
      color: var(--header-nav-color);
      cursor: pointer;
      font-size: 14px;
      font-family: 400;
      line-height: 16.8px;
      margin: unset;
    }
  }
}

.settings__general-info__form__fields .text-field {
  width: 100%;
}

.default-hours {
  width: 170px;
}

.settings__time-off {
  width: 100%;
  overflow: auto;
  padding: 40px 34px 40px 60px;
}

.time-off__scroll-component {
  &::-webkit-scrollbar-track {
    border: 1px solid var(--stroke);
    border-radius: 8px;
  }
}

.time-off__scroll-component {
  padding-right: 20px;
}

.time-off__control-row {
  padding-right: 26px;
}

.settings__delete {
  text-align: start;

  .settings__delete-text {
    font-size: 1.1rem;
    margin-bottom: 45px;
    color: var(--text-2);
  }

  .settings__delete-important-text {
    font-size: 1.1rem;
    color: var(--error);
  }

  .settings__delete-button {
    position: absolute !important;
    bottom: 55px;
    right: 55px;
    background: transparent !important;
    border: 1px solid var(--error) !important;
    color: var(--error) !important;
    border-radius: 7px;
  }
}

.settings__delete > p {
  font-size: 1.1rem;
}

.settings__delete > h4 {
  text-decoration: underline;
}

.account-schedule {
  display: flex;
  flex-direction: column;
  padding: 35px 100px 100px 100px;
  position: relative;
  color: var(--text-2);

  .MuiOutlinedInput-input {
    padding: 12px 14px;
  }

  .settings__submit {
    width: fit-content;
    margin-top: 30px;
  }

  .settings__edit-value {
    margin-bottom: 40px;

    .settings__switch-container {
      position: relative;
      display: flex;
      width: 200px;
      height: 30px;
      border-radius: 7px;
      color: var(--text-1);
      background-color: var(--background-1);
      cursor: pointer;
      font-family: Montserrat;

      .settings__switch-button {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        user-select: none;
        z-index: 1;
        border: 1px solid transparent;

        &.inactive {
          border: 1px solid var(--stroke);
          border-radius: 0 7px 7px 0px;

          &:first-child {
            border-radius: 7px 0 0 7px;
          }
        }
      }

      .settings__switch-active-button {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        background-color: var(--stroke);
        border-radius: 7px 0 0 7px;
        transition: transform 0.1s ease;

        &.right {
          border-radius: 0 7px 7px 0;
        }
      }
    }

    .form-error__error-text,
    .form-error__main-text {
      color: #fff !important;
    }

    p {
      margin: 0 0 5px 0;
      text-align: left;
      font-size: 14px;
      color: var(--text-2);
    }

    .settings__edit-header__text {
      font-size: 14px;
      margin: 0 0 5px 0;
      line-height: 22px;
      letter-spacing: -0.408px;
    }
  }
}

.subscription__annual-box {
  border: 1px solid var(--stroke);
  border-radius: 10px;
  width: 45%;
  cursor: pointer;
  text-align: center;
  padding-top: 10px;
  height: 110px;
}

.tb-default-dialog {
  .MuiPaper-root {
    border-radius: 20px;
    background-color: var(--background-1) !important;
    box-shadow: var(--box-shadow);
    background-image: none !important;
  }

  .input-form__form-text {
    font-size: 14px;
  }

  .MuiDialogTitle-root {
    margin: 30px 40px 0px 40px;
    padding: 0px 0px 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    color: var(--text-1);
    font-weight: 400;

    @media (max-width: 1100px) {
      margin: 15px 30px 0px 30px;
    }

    .MuiTypography-h6 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    p {
      margin: 0;
    }

    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .MuiDialogContent-root {
    scrollbar-gutter: stable;
    overflow: overlay;
    padding: 0px 30px;
    color: var(--text-2);

    textarea {
      color: var(--text-2);
      background: var(--background-1);
      width: 100%;
      height: 150px;
      resize: none;
      font-size: 14px;
      font-family: Montserrat;
      border: 1px solid var(--stroke);
      border-radius: 8px;
      outline: none;
      caret-color: var(--cursor-color);
    }

    .MuiInputBase-multiline {
      border: 1px solid var(--stroke);
      border-radius: 10px;
    }

    .MuiFormControl-root {
      background: var(--background-1);
      outline: none;
      box-shadow: none;

      textarea {
        border: none;

        ::placeholder {
          color: var(-text-2);
          opacity: 0.5;
        }
      }

      label {
        padding: 0 5px;
        background: var(--background-1);
        font-family: Montserrat;
        color: var(--text-2);
        opacity: 0.5;
      }

      label.Mui-focused {
        opacity: 1;
        color: var(--button-outline-text-default);
      }

      .MuiInput-underline:after {
        border-bottom-color: #b2bac2;
      }

      .MuiOutlinedInput-root {
        & fieldset {
          border-color: transparent;
        }

        &:hover fieldset {
          border-color: transparent;
        }

        &.Mui-focused fieldset {
          border-color: transparent;
        }
      }
    }

    @media (max-width: 1100px) {
      padding: 0px 30px;
      max-width: 100%;
    }
  }

  .MuiDialogActions-root {
    padding: 0px 40px 30px 40px;

    img {
      cursor: pointer;
    }
  }

  a {
    color: var(--link-color) !important;
  }
}

.tb-default-dialog__delete {
  .MuiPaper-root {
    border-radius: 20px;
    position: relative;
    background: var(--background-1) !important;
    box-shadow: var(--box-shadow);
    background-image: none !important;
  }

  .MuiDialogTitle-root {
    margin: 30px 40px 0px 40px;
    padding: 0px 0px 10px 0px;
    border-bottom: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--text-1);

    .close-icon {
      position: absolute;
      right: 25px;
      top: 30px;
      cursor: pointer;
    }

    p {
      margin: 0;
    }
  }

  .MuiDialogContent-root {
    scrollbar-gutter: stable;
    padding: 0px 40px;
    margin: 20px 0px;
    color: var(--text-2);
  }

  .MuiDialogActions-root {
    padding: 0px 40px 30px 0px;
  }
}

.settings_page {
  .input-form__form-text {
    font-size: 14px;
  }

  .tb-react-select__field-name {
    font-size: 14px !important;
    color: var(--text-2);
  }
}

.selector-container {
  margin-bottom: 16px;

  p {
    margin-bottom: 0;
  }
}

.time-off__scroll-component {
  overflow-y: auto;
  max-height: 140px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.time-off__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-1);
  box-sizing: border-box;
  border-radius: 7px;
  padding: 8px 20px;
  color: var(--text-1);
}

.time-off__block-content {
  display: flex;
  gap: 20px;
  align-items: center;

  .teambook-icon-frame {
    border: 1px solid var(--stroke) !important;
  }
}

.time-off__description {
  display: flex;
  align-items: center;
  text-align: left;

  p {
    margin-bottom: 0;
    font-size: 14px;
    color: var(--text-1);
  }
}

.time-off__control-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;

  p {
    color: var(--text-1);
    margin: 0;
    line-height: 19.2px;
    font-size: 16px;
  }

  .teambook-icon-frame {
    border: 1px solid var(--stroke) !important;
  }
}

.integrations__integration-list {
  overflow: auto;
}

.main-settings-component {
  display: flex;
  flex-direction: column;
  padding: 35px 60px;
  color: var(--text-2);

  .integration-name {
    margin-left: 10px;
    min-width: 120px;
    width: fit-content;
  }

  .integration-block {
    width: 97.5%;
    height: 64px;
    display: flex;
    padding: 5px 15px;
    gap: 55px;
    align-items: center;
    background-color: var(--background-1);

    border-radius: 7px;
    color: var(--text-2);

    button {
      margin-left: auto;
    }

    img {
      height: 40px;
    }
  }

  .integration-status {
    p {
      margin: 0px;
    }

    .active {
      color: #048929;
    }

    .not_active {
      color: #b68300;
    }

    &.qb-status {
      p {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .tb-integration__token {
    p {
      white-space: nowrap;
      margin: 0px;
    }
  }

  .greeting {
    margin-top: 20px;
    margin-bottom: 0px;
    text-align: left;
  }

  .greeting__header {
    color: var(--text-1);
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-bottom: 8px;
  }

  .greeting__text {
    color: var(--text-2);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
    margin-bottom: 0px;
    padding-bottom: 16px;
  }

  .custom-field {
    display: flex;
    gap: 40px;

    .input-form-component {
      width: 48%;
    }
  }
}

.integrations__group-title {
  color: var(--text-1);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  margin-bottom: 10px;
  text-align: left;
}

.integrations__integration-group {
  &:first-child {
    margin-top: 14px;
  }

  margin-top: 30px;
}

.integrations__group-apps {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.org-settings-main-container {
  position: relative;
  width: 220px;
  & div:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  & div:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
}

.org-settings-options {
  position: absolute;
  top: 80px;
  border-radius: 10px;
  border: 1px solid var(--stroke);

  :last-child {
    border-bottom: unset !important;
  }
}

.teambook-icon-frame {
  position: relative;
  height: fit-content;
  width: fit-content;
  border-radius: 7px;
  border: 1px solid var(--icon-stroke) !important;
  padding: 8px 10px;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }

  &.is-settings {
    position: absolute;
    right: 50px;
  }
}

.actuals-blocked-icon {
  position: absolute;
  bottom: 150px;
  right: 60px;

  @media (max-height: 800px) {
    transform: scale(0.8);
    bottom: 50px;
  }
}

.customization-sub-component {
  background-color: var(--background-1) !important;
  border-radius: 7px;
  padding: 20px;

  .customization-sub-component__title {
    text-align: start;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .customization-action-buttons {
    display: flex;
    justify-content: end;
  }
}

.credentials__email-changing__modal {
  .MuiDialogTitle-root {
    padding: 0;
  }
  .MuiDialogContent-root {
    margin: 20px 0px;
    width: 500px;
    scrollbar-gutter: unset;
  }
  .MuiDialogContentText-root {
    color: var(--text-2);
    margin-bottom: 20px;
  }
  .input-form-component {
    margin: 0;
  }
  .input-form__form-text {
    margin: 0px;
  }
}

.credentials__email-switch__modal {
  .MuiDialogTitle-root {
    padding: 0;
  }
  .MuiDialogContent-root {
    margin: 20px 0px;
    min-width: 500px;
    scrollbar-gutter: unset;
  }
  .MuiDialogContentText-root {
    .first-text {
      color: var(--text-2);
      margin-bottom: 0;
    }
    .first-bolder-text {
      color: var(--text-6);
    }
    .second-text {
      color: var(--text-2);
      margin-bottom: 20px;
    }
  }
  .input-form-component {
    margin: 0;
  }
  .input-form__form-text {
    margin: 0px;
  }
}

.credentials__password-changing__modal {
  .MuiPaper-root {
    max-width: unset !important;
  }

  .MuiDialogTitle-root {
    padding: 0;
  }
  .MuiDialogContent-root {
    margin: 20px 0px;
    min-width: 500px;
    max-width: 800px;
    scrollbar-gutter: unset;
    .password-changing__inputs {
      display: flex;
      .password-changing-first__input {
        margin-right: 40px;
      }
    }
  }
  .MuiDialogContentText-root {
    color: var(--text-2);
    margin-bottom: 20px;
  }
  .input-form-component {
    margin: 0;
  }
  .input-form__form-text {
    margin: 0px;
  }
}

.qb-data-block {
  margin-bottom: 10px;

  .qb-data-block__title {
    font-size: 18px;
    font-weight: 500;
  }

  .qb-data-block__list {
    padding-left: 20px;
    max-height: 200px;
    overflow-y: auto;
    font-size: 14px;
  }
}

.settings-page__container {
  position: fixed;
  top: 120px;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 20px;
  overflow: auto;
}

.settings__scroll-container {
  max-height: 730px;
  padding-right: 20px;

  @media (max-height: 1100px) {
    max-height: 650px;
  }
}

.settings__right-side__container {
  background-color: var(--background-2);
  width: 985px;
  border-radius: 10px;
  height: fit-content;
  max-height: 650px;
  overflow: auto;
  overflow-x: hidden;
}

.settings__right-side__container::-webkit-scrollbar {
  background: red;
}

.settings__right-side__container .account-schedule {
  overflow-y: hidden;
  max-height: 100%;
}

.settings__edit-container {
  display: flex;
  position: relative;
  padding: 70px 120px 70px 120px;
  gap: 100px;

  .settings__edit-block {
    flex: 1;

    .settings__edit-value {
      margin-bottom: 20px;

      .form-error__error-text,
      .form-error__main-text {
        color: #fff !important;
      }

      p {
        margin: 0 0 5px 0;
        text-align: left;
        font-size: 14px;
        color: var(--text-2);
      }

      .settings__edit-header__text {
        font-size: 14px;
        margin: 0 0 5px 0;
        line-height: 22px;
        letter-spacing: -0.408px;
      }
    }
  }
}

.settings__show-container {
  display: flex;
  padding: 70px 120px 35px 120px;
  position: relative;
  justify-content: space-between;

  .settings_show-block {
    width: 40%;

    .settings_show-value {
      margin-bottom: 20px;
      min-height: 85px;
      p {
        text-align: left;
      }

      .name {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
        color: var(--text-2);
      }

      .value {
        color: var(--text-2);
        width: 90%;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
}

.settings__integration-hubspot__row {
  display: flex;
  margin: 10px 0 10px 0;

  p {
    margin: 9px 20px 0 20px;
  }
}

.subscription-modal-dialog__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--stroke);
  padding: 20px 24px;

  p {
    color: var(--text-1);
    margin: 0;
    font-size: 1.3rem;
  }
}

.vat-text {
  color: #be1d1d;
  margin-top: 20px;
}

.subscription__customer-info {
  display: flex;

  &.subscription-kind {
    justify-content: space-between;
    padding: 0 15px;

    p {
      margin: unset;
      cursor: pointer;
    }

    .tb-radio-button {
      margin-right: 5px;
    }
  }
}

.invoice__modal-container {
  display: flex;
  justify-content: space-between;

  .invoice__modal-text {
    margin: 10px 20px;
  }
}

.subscription-container {
  padding: 25px 125px 35px 125px;
  color: var(--text-2);

  .selected-subscription {
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 0px;

    p {
      text-align: start;
    }
  }

  .subscription__card-plan {
    height: 60px;
    background-color: var(--background-1);
    margin: 0;
    display: flex;
    align-items: center;
    border-radius: 7px;
    color: var(--text-2);

    .subscription__name-plan {
      display: flex;
      justify-content: space-between;
      text-align: start;
      margin: 0;

      .popular-plan {
        background-color: var(--accent-light);
        padding: 3px 10px 0 10px;
        border-radius: 10px;
        color: var(--background-1);
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
      }
    }

    .subscription__projects-plan {
      margin: 0;
    }
  }
}

.subscription__change-payment__container {
  display: flex;
  justify-content: space-between;

  p {
    text-align: start;
  }
}

.subscription__link {
  text-decoration: underline;
  color: var(--text-2);
  cursor: pointer;
  transition: color var(--transition-duration);
  :hover {
    color: var(--link-color);
  }
}

.settings-option {
  width: 220px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 400;

  p {
    margin: unset;
    text-align: start;
    font-size: 16px;
    line-height: 30px;
    color: var(--text-2);
  }
}

.payroll-icon {
  width: max-content;
  border-radius: 20px;
  padding: 5px;
  cursor: pointer;
}

.time-off__icon {
  width: max-content;
  border-radius: 20px;
  padding: 5px;
  cursor: pointer;
}

.time-off_icon-block {
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
}

.leave-modal__option {
  display: flex;
  margin-bottom: 12px;

  p {
    margin: 0 0 0 10px;
    cursor: pointer;
    user-select: none;
  }
}

.leave-modal__dialog {
  .MuiDialog-paper {
    min-width: 600px;
  }
}

.leave-modal__textarea {
  width: 100%;
  height: 80px;
  border: 0;
  resize: none;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  padding: 10px;
  &:focus,
  &:focus-visible {
    outline: 0;
    border: 0;
  }
}

.save-notification {
  margin-left: 640px !important;
  width: fit-content;
}

.azure-sync-dialog {
  .MuiDialog-paper {
    height: 386px;
    width: 500px;
  }

  .MuiDialogTitle-root {
    border: none;
    padding-bottom: 20px;
  }

  .MuiDialogContent-root {
    padding: 0px 40px;
  }

  .azure-sync__content {
    .tb-radio-button {
      margin-right: unset;
    }
    p {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.523px;
      margin: 0px 20px;
    }
    .azure-sync__content-line {
      display: flex;
      width: 100%;
      min-height: 44px;
      align-items: center;
    }
  }

  .azure-sync__button {
    font-weight: 400;
    margin-left: 20px;
  }
}

.personio__sync-setting {
  margin: 15px 0;
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;

  .personio__time-off {
    display: flex;
    justify-content: space-between;
    padding: 8px 0 8px 20px;

    p {
      margin: 9px 0 0 0;
      font-size: 14px;
    }
  }

  .personio__no-time-off-types {
    text-align: center;
    margin-top: 60px;
  }

  .personio__main-setting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    p {
      margin: unset;
    }
  }
}

.personio__description {
  font-size: 13px;
  margin: unset;
}

.personio__title {
  margin: 20px 0 0 0;
}

.personio__sub-setting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin-left: 60px;

  p {
    max-width: 350px;
    word-wrap: break-word;
    margin: unset;
  }
}
