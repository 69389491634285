$new_blue: #4c54a0;

.filter-row {
  border-bottom: 1px solid var(--stroke);
  height: 60px;
  display: flex;
  z-index: 22;
}

.projects-filter-row__filter-input {
  height: 100%;
  width: 50%;
  border-right: 1px solid var(--stroke);
  @media (max-width: 640px) {
    width: 100%;
    border: 0px;
  }
}

.project-form__project-color.timeoff {
  background-color: #7a7a7a;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filter-row__search-icon {
  width: 25px !important;
  height: 25px !important;
  margin: 17px;
}

.projects-filter-row__buttons {
  width: 40%;
  display: flex;
  justify-content: space-between;
}
.projects__active-entity-switch__option {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    border-radius: 6px 0px 0px 6px;
  }

  &:last-child {
    border-radius: 0px 6px 6px 0px;
  }

  p {
    color: var(--text-2);
    margin: 0 15px;
    font-size: 14px;
    letter-spacing: 0.4px;
  }
}

.projects__active-entity-switch__option.active {
  background: var(--stroke);
}

.filter-row__button {
  border: 1px solid var(--stroke) !important;
  background-color: var(--background-1) !important;
  font-size: 0.9rem !important;
  text-transform: unset !important;
  margin-top: 10px !important;
  margin-left: 15px !important;
}

.filter-row__button-add {
  border: 1px solid #d4d9dc !important;
  background-color: #457ec1 !important;
  color: #ffffff !important;
  box-shadow: unset !important;

  height: 38px;

  font-size: 0.9rem !important;
  text-transform: unset !important;
  margin-top: 10px !important;
  margin-right: 15px !important;
}

.projects-page__projects-content {
  position: fixed;
  top: 165px;
  bottom: 0;
  width: 100%;
  z-index: 10;
  padding-right: 30px;
  overflow: auto;
}

.projects-page {
  .MuiFormControl-root {
    margin: 0px !important;
  }
}

.projects-page__project-list {
  width: fit-content;
  overflow-y: visible;
  scrollbar-gutter: stable;
  color: var(--text-2);
}

.projects-show__estimated__bar {
  margin-left: 2%;
  width: 31%;
}

.MuiDialog-root {
  &[project-type="time_off"] {
    .MuiPaper-root {
      height: 780px;
    }

    .time-off__icon {
      display: flex;
    }

    .MuiTypography-root {
      padding-top: 40px;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      p {
        margin: 11px auto;
      }
    }
  }
}

.project-form__progress {
  width: 100%;
  margin-bottom: 25px;

  .projects-show__estimated__text__first {
    width: 20%;
  }

  .projects-show__estimated__text__second {
    margin-left: 2% !important;
  }

  .projects-show__estimated__text {
    width: 100%;
    display: flex;

    align-items: center;

    p {
      margin: 0;
    }
  }
}

.MuiLinearProgress-barColorPrimary {
  background-color: var(--header-nav-color) !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: var(--estimated-bg) !important;
}

.project-form__row_progress {
  justify-content: center !important;
}

.projects-show__estimated__text p {
  display: flex;
  gap: 3px;
}

.projects-page__project-list-header {
  width: fit-content;
  display: flex;
  height: 50px;
  align-items: center;
  gap: 20px;
  overflow: visible;
  scrollbar-gutter: stable;
  position: sticky;
  top: 0;
  background: var(--background-1);
  z-index: 3;

  @media (max-width: 600px) {
    padding: 0px 0px 0px 10px;
    gap: 10px;
  }

  p {
    text-align: left;
    margin: 0;
    color: #474747;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }

  .bookings_estimated {
    justify-content: flex-end !important;
  }

  .projects-page__project-list-header_field {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;

    img {
      @media (max-width: 1366px) {
        width: 18px;
        height: 18px;
      }
    }

    p {
      color: var(--text-2);
      margin: 0px;
    }
  }

  .projects-page__project-list-header_field.name {
    @media (max-width: 600px) {
      width: 40% !important;
    }
  }

  .projects-page__project-list-header_field.client_name {
    @media (max-width: 600px) {
      width: 20% !important;
    }
  }

  .projects-page__project-list-header_field.kind {
    @media (max-width: 600px) {
      width: 30% !important;
    }
  }

  .projects-page__project-list-header_estimated {
    display: flex;
    flex: 1;
    cursor: pointer;
    max-width: 100%;
    overflow: hidden;
  }

  .projects-page__project-list-header_estimated-hours {
    margin-left: auto;
    margin-right: 30px;
  }
}

.budget__not-estimated {
  align-items: center;

  .project-budget__text {
    margin: 0px !important;
  }
}

.not_estimated {
  margin: 0px !important;
}

.project-list__block {
  width: 100%;
  display: flex;
  align-items: center;

  p {
    color: var(--text-2);
  }

  :hover {
    background: var(--stroke);
  }

  &.is-selected {
    background: var(--background-2);
  }

  .project-list__project-main-data {
    display: flex;
    gap: 10px;
    align-items: center;
    overflow: hidden;

    @media (max-width: 600px) {
      width: 40%;
    }
  }

  .project-list__name,
  .project-list__code,
  .project-list__client {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .project-list__code {
    font-weight: 400;
  }

  .project-list__client {
    @media (max-width: 600px) {
      width: 20%;
    }
  }

  .project-list__kind {
    white-space: nowrap;

    @media (max-width: 600px) {
      width: 30%;
    }
  }

  .project-list_actuals {
    margin-top: 8px;
  }

  //p {
  //  overflow: hidden;
  //  white-space: nowrap;
  //  text-overflow: ellipsis;
  //  word-wrap: break-word;
  //}

  .project-list__estimated {
    display: flex;

    flex-direction: column;
    justify-content: center;

    .projects-block__estimated {
      width: 100%;
      margin-right: 0px;

      .projects-block__estimated__bar {
        margin-top: 0px;
        flex: 1;
      }
    }
  }

  .project-list__project-color {
    min-width: 10px;
    max-width: 10px;
    height: 24px;
    border-radius: 4px;
  }

  p {
    margin: 0;
    text-align: left;
  }
}

.projects-page__project-element {
  display: flex;
  align-items: center;
  height: 48px;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  gap: 20px;
  flex: 1;
  width: 100%;
  font-size: 14px;

  @media (max-width: 600px) {
    padding: 0px 0px 0px 10px;
    gap: 10px;
  }
}

.projects-page__project-form {
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 750px;
  height: 695px;
  box-shadow: -1px 5px 10px 1px rgba(51, 50, 50, 0.25) !important;
  background-color: #fff;
  border-radius: 10px;

  @media (max-width: 600px) {
    overflow: scroll;
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: 100;
  }
}

.edit-project__value p {
  margin: auto;
  text-align: center !important;
  margin-bottom: 5px;
  width: 100%;
}
.form-error__main-text {
  font-size: 0.9rem;
}

.projects-block__buttons button {
  padding: 0px 9px;
  color: #333333;
}

.projects-block__tags {
  display: block;
  overflow-y: auto;
  margin: 8px 0 8px 0;
  max-width: 200px;

  font-size: 0.9rem;
}

.projects-block__tags__name {
  display: inline-block;
  height: fit-content;
  border-radius: 10px;

  color: #ffffff;

  padding: 2px 5px 2px 5px;
  margin: 2px 3px 2px 3px;
}

.project-block__status {
  background-color: #5e6ab8;
  border-radius: 100px;
  line-height: 22px;
  font-size: 0.9rem;
  color: white !important;
  width: 100px;
}

.projects-block__estimated__bar {
  height: 8px !important;
  margin-top: 7px;
  width: 100%;
  border-radius: 10px;
}

.projects-block__estimated__text {
  font-size: 0.9rem;
  display: flex;
  justify-content: space-between;
}

.project-circle-picker__container {
  .MuiMenu-paper {
    border-radius: 10px !important;
    border: 1px solid var(--stroke);
    background: var(--background-1) !important;
    box-shadow: var(--box-shadow);
  }

  .MuiModal-backdrop {
    background: none !important;
  }
}

.project-circle-picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  width: 254px;
  height: fit-content;
  border-radius: 7px;

  .sketch-picker {
    background: var(--background-1) !important;
    box-shadow: 0 0 1px var(--link-color) !important;

    input {
      outline: none !important;
      caret-color: var(--cursor-color) !important;
      background: var(--background-1) !important;
      border: none !important;
      box-shadow: 0 0 1px var(--link-color) !important;
      color: var(--text-2) !important;
    }

    label {
      color: var(--text-2) !important;
    }
  }

  button {
    font-family: Montserrat;
  }

  .circle-picker {
    width: 254px !important;
  }
}

.projects-page__user-form {
  box-shadow: -1px 5px 10px 1px rgba(51, 50, 50, 0.25) !important;
  background-color: #fff;
  border-radius: 10px;

  @media (max-width: 600px) {
    overflow: scroll;
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: 100;
  }
}

.project-form__select-value__control {
  height: 43px;
  font-size: 16px;
}

.project-form__left-side,
.project-form__right-side {
  .project-form__field-name,
  .project-form__field-value {
    text-align: left;
    margin: 0;
    white-space: nowrap;

    .MuiInputBase-root {
      color: var(--text-2);
      border-bottom: none;

      &:before {
        border-bottom: 1px solid var(--text-1);
      }

      &:after {
        border-bottom: 1px solid var(--link-color);
      }

      &:hover {
        &:before,
        &:after {
          border-bottom: 1px solid var(--text-1);
        }
      }
    }
  }
}

.project-form__left-side {
  width: 100px;

  @media (max-width: 600px) {
    padding: 0px 10px;
    width: 35%;
  }
}

.project-form__right-side {
  width: calc(100% - 120px);
  position: relative;
  @media (max-width: 600px) {
    width: 65%;
  }
}

.client-creation-dialog {
  .MuiDialogTitle-root {
    border-bottom: 1px solid #d5d9ff;
    display: flex;
    p {
      margin: 0px;
    }
  }

  .MuiDialogContent-root {
    width: 320px;
    padding: 20px 24px;
    padding-top: 20px !important;
  }

  .client-creation-dialog__value-row {
    width: 100%;
    margin: 8px 0px;

    .client-creation-dialog__value-name {
      margin: 4px;
    }
  }

  .form_close {
    margin-left: auto;
    cursor: pointer;
    width: 24px;
    height: 24px;
    z-index: 15;

    @media (max-width: 600px) {
      top: 75px;
      right: 10px;
    }
  }
}

.project-form__row.dates {
  height: 30px;
}

.draft-js-editor__control {
  color: var(--text-1);
  font-size: 12px;
}

.project-form__row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: fit-content;

  .project-form__field-name {
    font-size: 16px;
    color: var(--text-2);
    margin-bottom: 20px;
    height: fit-content;
  }

  .DraftEditor-root {
    height: 60px;
  }

  .draft-js-editor__controls {
    height: 28px;
    padding: 0px 5px;
    align-items: center;

    div {
      color: var(--accent-dark);
    }
  }

  .project-form__field-name {
    font-size: 14px;
    color: var(--text-2);
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 2px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    height: unset;
    gap: 5px;
    margin-bottom: 5px;
    padding-left: 10px;
  }

  .user-form__field-input {
    margin: 0 !important;
    width: 100% !important;

    .MuiOutlinedInput-root {
      font-family: Montserrat !important;
    }

    &.estimated-hours {
      width: 40% !important;
    }
  }

  .estimated-hours__text {
    font-size: 14px;
    margin: 10px 10px 0 5px;
  }
}

.project-form__estimated-hours {
  height: unset !important;
  margin-bottom: 10px;
  gap: 10px;
  width: 80%;

  b {
    font-weight: 500;
    font-size: 18px;
  }
}

.project-show__estimated {
  width: 65%;
  display: flex;
  justify-content: space-between;

  p {
    margin-bottom: 0;
  }
}

.project-view__statistics {
  position: absolute;
  top: 145px;
  bottom: 100px;
  right: 38px;
  left: 38px;
  background-color: var(--background-2);
  border-radius: 10px;

  .statistics-box {
    display: flex;
    margin: 20px 20px 0 20px;
    background-color: var(--background-1);
    padding: 20px;
    border-radius: 10px;

    .statistics-icon {
      width: 5%;
      display: block;

      img {
        margin-top: 16px;
      }
    }

    .statistics-value {
      font-size: 14px;
      display: block;
      flex-grow: 0;
      flex-shrink: 1;
      padding-right: 30px;
      padding-left: 10px;

      p {
        margin-top: 18px;
        margin-bottom: 0;
      }
    }

    .statistics-linear-bar {
      flex-grow: 1;
      flex-shrink: 1;

      .MuiLinearProgress-root {
        height: 7px;
        border-radius: 7px;
      }

      p {
        margin-top: 11px;
        margin-bottom: 0;
        font-size: 14px;
        text-align: center;
      }
    }

    .statistics-estimated-hours {
      padding-left: 30px;
      flex-grow: 0;
      flex-shrink: 1;

      p {
        margin-top: 50px;
        font-size: 14px;
      }
    }
  }
}

.project-form__tasks-tab {
  max-height: 550px;
  height: fit-content;
  position: relative;
  display: flex;
  flex-direction: column;

  .project-form__tasks-title {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;

    img {
      cursor: pointer;
    }

    p {
      margin: 0px;
      color: var(--text-1);
      font-size: 16px;
    }
  }

  .project-form__task-list {
    max-height: 300px;
    height: fit-content;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .project-form__task {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 60px;
      max-height: 60px;
      background-color: var(--background-2);
      border-radius: 7px;
      padding: 0px 20px;

      ::placeholder {
        color: var(--text-2);
        font-size: 14px;
        letter-spacing: -0.408px;
      }

      &[mode="create"] {
        background: unset;
        padding: 0px 20px 0px 0px;

        .input-form-component {
          width: 70%;
        }
      }

      p {
        margin: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: var(--text-2);
        font-size: 14px;
        letter-spacing: -0.408px;
      }
    }

    .input-form-component {
      margin: 0px;
    }

    .project-form__task-actions {
      margin-left: auto;
      display: flex;
      gap: 20px;

      .project-form__icon-wrapper {
        width: 44px;
        height: 40px;
        border: 1px solid var(--stroke);
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      svg,
      path {
        min-width: 24px;
        min-height: 24px;
        stroke: var(--accent-dark);
        fill: transparent;
      }
    }
  }

  .project-form__tasks-tab-actions {
    width: 100%;
    padding: 20px 0px 40px 0px;
    display: flex;
    justify-content: flex-end;
  }
}

.form-create.projects-form {
  padding: 0px;
}

.task-list__task {
  display: flex;

  padding: 6px 8px;
  align-items: center;
  gap: 5px;
  border-radius: 20px;
  background: var(--background-2);

  p {
    margin: 0px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px; /* 100% */
    letter-spacing: 0.15px;
    color: var(--text-2);
  }
}

.form-show {
  .project-form__field-name {
    margin-bottom: 10px;
    letter-spacing: -0.408px;
    font-weight: 500;
  }

  .project-form__field-value {
    font-size: 14px;
  }
}

.project-form__row_notes {
  flex-direction: column;
  width: 100%;

  p {
    white-space: unset !important;
  }
}

.project-form__empty-notes {
  color: #d7d7d7;
}

.form__duplicate-button-exceeded {
  background: #ff0000;
  width: 140px;
  white-space: nowrap;
  &:hover {
    background: #ff0000;
  }

  @media (max-width: 600px) {
    width: fit-content;
  }
}

.form__duplicate-button {
  width: 140px;
  white-space: nowrap;

  @media (max-width: 600px) {
    width: fit-content;
  }
}

.project-form__project-color {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  border-radius: 25px;
}

.project-form__notes {
  width: 100%;
  max-height: 140px;
  overflow-y: auto;
  text-overflow: ellipsis;
}

.project-form__notes-header {
  width: 100%;
  height: 35px;
  background: #f4f4f4;
}

.project-form__row_date {
  @media (max-width: 600px) {
    flex-direction: row;
  }
}

.project-form__full-row-value {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  .user-form__field-input {
    width: 100% !important;
  }
}

.project-form__field-name {
  font-size: 16px;
  color: var(--text-2);
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 7px;
}

.project-form__field-value {
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;

  .tb-react-select__control {
    background: transparent;
    border: 1px solid var(--stroke) !important;
  }

  .MuiFormHelperText-root {
    display: none;
  }

  &:hover {
    .project-form__hidden-hover {
      display: block;
      visibility: visible;
    }
  }

  .project-form__hidden-hover {
    visibility: hidden;
    position: absolute;
    z-index: 2;
    border: 1px solid #c8d2e6;
    font-size: 14px;
    padding: 5px;
    border-radius: 7px;

    p {
      margin: 0;
    }
  }
}

.project-form__full-field {
  width: 100%;

  .tb-react-select-multi__control {
    box-shadow: unset !important;
  }

  .beta-image {
    height: 21px;
    margin-left: 10px;
  }
}

.project-form__field {
  width: 48%;

  @media (max-width: 600px) {
    width: 90%;
  }

  .project-form__field-value-checkbox {
    .MuiCheckbox-root {
      padding: 0;
    }
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;

    p {
      margin: 0;
      white-space: nowrap;
    }
  }
}

.projects-block__estimated__text_empty {
  font-size: 0.9rem;
  display: flex;
}

.filter-row__mobile-button {
  @media (max-width: 1100px) and (min-width: 641px) {
    width: 36px;
    height: 36px;
    margin: 0px;
  }
  @media (max-width: 640px) {
    width: 24px;
    height: 24px;
    margin: 0px;
  }

  opacity: 0.55;
}

.projects-block__estimated {
  width: inherit;
  margin-right: 20px;
}

body .archive-dropdown {
  width: auto;
  min-width: 150px;
  margin-top: 11px;
}

.project__buttons {
  display: flex;
  border-top: 1px solid #e6e6e9;
  margin-bottom: 0px;
  padding-bottom: 20px;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  background: #fff;
  z-index: 3;
  width: calc(100% - 20px);
}

.project-dates {
  .MuiInputBase-input,
  .MuiInputBase-root {
    padding: 0px;
    font-size: 14px;
    font-family: Montserrat;
    width: 140px;
  }

  .tb-checkbox {
    padding: 0px;
    margin-right: 10px;
  }

  .project-form__field {
    display: flex;
    align-items: center;
  }
  .project-form__field-name {
    margin: 0px;
  }

  .project-form__field-value {
    display: flex;
    margin-left: 5px;
    align-items: center;
    gap: 10px;
  }
}

.edit-project-form {
  position: absolute;
  bottom: 100px;
  top: 54px;
  width: 100%;
  overflow-y: auto;
  padding: 0 5% 0 5%;
}

.user-list__separator__text {
  white-space: nowrap;
}
.projects-block__content strong {
  font-size: 0.9rem;
  width: fit-content;
  min-width: 38%;
  text-align: left;
}
.projects-block__estimated__text p {
  margin-bottom: 0;
}
.project-bottom-block {
  display: flex;
  width: 100%;

  justify-content: space-between;
}
.projects-block__estimated {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-form__creation-info {
  font-size: 12px;
  color: var(--text-2);

  b {
    color: var(--text-1);
  }

  p {
    margin: 0px;
  }
}

.project-list__select-checkbox {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;

  input[type="checkbox"] {
    accent-color: #474747;
    width: 18px;
    height: 18px;
  }
}

.columns-settings-button {
  display: flex;
  cursor: pointer;
  padding: 7px;
  border: 1px solid var(--stroke);
  border-radius: 7px;
  height: 40px;

  .planner-control-panel__setting-icon {
    display: flex;
  }

  .control-bar__dropdown-fields {
    cursor: default;
    width: fit-content;
  }

  .columns-selection-option {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    cursor: pointer;

    img {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background: var(--background-2);
    }
  }
}

.text-field {
  &.MuiInputBase-root {
    border-bottom: 1px solid var(--stroke) !important;
  }
}

.custom-fields {
  .custom-fields-inputs {
    display: flex;
    justify-content: space-between;
  }

  .custom-field__input {
    width: 48%;
  }

  .custom-fields-title {
    color: var(--text-1);
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 20px;
  }
  p {
    font-size: 14px;
  }
}

@media (max-width: 1400px) {
  .column-width-1 {
    min-width: 130px;
    max-width: 130px;
  }
  .column-width-2 {
    min-width: 125px;
    max-width: 125px;
  }
  .column-width-3 {
    min-width: 160px;
    max-width: 160px;
  }
  .column-width-4 {
    min-width: 270px;
    max-width: 270px;
  }
}

@media (min-width: 1400px) {
  .column-width-1 {
    min-width: 140px;
    max-width: 140px;
  }
  .column-width-2 {
    min-width: 150px;
    max-width: 150px;
  }
  .column-width-3 {
    min-width: 190px;
    max-width: 190px;
  }
  .column-width-4 {
    min-width: 320px;
    max-width: 320px;
  }
}

@media (min-width: 1950px) {
  .column-width-1 {
    min-width: 160px;
    max-width: 160px;
  }
  .column-width-2 {
    min-width: 190px;
    max-width: 190px;
  }
  .column-width-3 {
    min-width: 240px;
    max-width: 240px;
  }
  .column-width-4 {
    min-width: 400px;
    max-width: 400px;
  }
}

.projects__import-inline-text {
  p {
    display: inline-block;
    margin-bottom: unset;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.41px;
  }

  .projects__import-link-text {
    color: var(--header-nav-color);
    text-decoration: underline;
    cursor: pointer;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.41px;
  }
}

.projects__import-defaul-text {
  p {
    margin-bottom: unset;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.41px;
  }
}

.projects__import-or-line {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 40px 0;

  .line {
    flex-grow: 1;
    height: 1px;
    background-color: var(--stroke);
  }
  p {
    font-size: 14px;
    margin: 0 10px;
    color: var(--header-nav-color);
  }
}

.exceeded-text {
  color: var(--text-2);
}

.mui-date-picker {
  background: unset !important;
  text-decoration: underline;

  .MuiIconButton-root {
    margin-left: -40px;
  }
}

.mui-date-picker .MuiOutlinedInput-root {
  background: transparent;

  & fieldset {
    border-color: transparent !important;
  }

  &:hover fieldset {
    border-color: transparent !important;
  }

  &.Mui-focused fieldset {
    border-color: transparent !important;
  }
}

.project-form__no-tasks {
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  margin-bottom: 0;
}
