:root[data-theme="light"] {
  --if-then-color: #4c54a0;
}

:root[data-theme="dark"] {
  --if-then-color: rgba(255, 255, 255, 0.6);
}

.register {
  display: flex;
  width: 100%;
  height: 100%;

  a {
    color: var(--header-nav-color) !important;
  }
}

.register__left-side {
  background-color: var(--stroke-transparent);
}

.register__left-side,
.register__right-side {
  width: 50%;
  position: relative;
}

.register__form {
  position: absolute;
  top: 90px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  width: 350px;
  text-align: left;

  button {
    margin-bottom: 50px;
  }

  .input-form__form-text {
    margin: 0px;
  }
}

@media (max-width: 1100px) {
  .register__right-side {
    width: 100%;
  }

  .register__form {
    top: 90px !important;
    width: 100% !important;
    padding: 0px 30px;
    margin: unset !important;
  }
}

.register__left-side-images {
  img {
    position: absolute;
  }
}

.register__left-side-image {
  bottom: 20px;
  @media (max-height: 850px) {
    transform: scale(0.7);
    bottom: -20px;
  }
}

.register__tb-logo {
  width: 245px;
  height: 34px;
  left: 50px;
  top: 50px;
}

.register__input-side {
  width: 45%;
}

.register__input-side__logo {
  display: flex;
}

.logo {
  padding-left: 35px;
  padding-top: 20px;
}

.register__input-side__form {
  margin-top: 15%;
  width: initial;
  grid-template-columns: 2fr 3fr 2fr;
  display: grid;
  grid-gap: 20px;
  grid-template-rows: 50px;
  grid-template-areas:
    ". a ."
    ". . ."
    ". b .";
}

.register__input-side__form__data {
  grid-area: b;
}

@media (max-width: 400px) {
  .register__input-side__form__data,
  .registry-terms {
    width: 100%;
  }
}

.register__input-side__head {
  grid-area: a;
  text-align: initial;
  font-size: 1.5rem;
}

.register__input-side__login-link {
  font-size: 0.9rem;
  text-decoration: unset;
}

@media (max-width: 640px) {
  .register__input-side__login-link {
    font-size: 0.8rem;
    text-decoration: unset;
    white-space: nowrap;
  }
}

.form-value {
  display: flex;
  align-self: center;
  justify-self: center;
  margin-bottom: 12px !important;
  width: 100%;
}

.form-value .text-field {
  min-width: 100%;
}

.validation-text {
  margin-left: 15px;
  min-width: fit-content;
}

.register__input-side__policy {
  grid-area: g;
  font-size: 0.9rem;
  white-space: nowrap;
}

.register__input-side__policy__links {
  display: flex;
  justify-content: center;
}

.register__input-side__policy__links p {
  margin-top: 0;
  margin-left: 5px;
  margin-right: 5px;
}

.register__proposal {
  position: absolute;
  text-align: left;
  margin-bottom: 20px;
  margin-top: 15px;
  top: 15%;
  left: 75px;

  p {
    color: var(--text-2);
    margin: 0;
  }

  .proposal {
    display: flex;
    align-items: start;
    gap: 20px;
    margin-bottom: 10px;
  }

  .then-container {
    display: flex;
    align-items: end;
    gap: 20px;
    margin-top: 20px;
  }

  .if,
  .then {
    line-height: 24px;
    font-size: 24px;
    color: var(--if-then-color);
  }

  .if {
    margin-bottom: 20px;
  }
}

@media (max-height: 680px) {
  .register__left-side-image {
    display: none;
  }

  .register__proposal {
    top: 30%;
  }
}

.register-pop-up__container {
  .MuiPaper-root {
    border-radius: 40px !important;
    max-height: 95%;
    max-width: 1530px;
    overflow: hidden !important;
    background-color: var(--background-1) !important;
    background-image: none !important;
  }

  .MuiDialogTitle-root {
    margin: 45px 0 0 0;
    padding: 0;
    text-align: center;
    color: var(--text-1);
    display: flex;
    flex-direction: column;
    align-items: center;

    .register-pop-up__container-header_second-row {
      display: flex;
      text-align: center;
      gap: 0.3em;

      p {
        text-transform: lowercase;

        &:last-child {
          text-transform: unset;
        }
      }
    }

    .register-pop-up__skip {
      color: var(--text-4);
      text-decoration: underline;
      cursor: pointer;
    }

    p {
      margin: 0;
    }

    img {
      position: absolute;
      top: 24px;
      bottom: 0px;
      margin: auto 0px;
      right: 75px;
      cursor: pointer;
    }
  }

  .MuiDialogActions-root {
    margin-right: 80px;
    padding: 0px 0px 40px 0px;
    gap: 60px;
  }

  .register-pop-up__content {
    display: flex;
    // height: 670px;
    padding: 20px 80px 20px 40px;

    .tb-radio-button {
      margin: 0px;
    }

    .register-pop-up__image {
      transform: scale(0.8);
    }

    @media (min-width: 1589px) {
      .register-pop-up__image {
        width: 50%;
      }
    }

    @media (max-width: 1490px) {
      .register-pop-up__image {
        width: 40%;
      }
    }

    @media (max-width: 1330px) {
      .register-pop-up__image {
        width: 45%;
      }
    }

    .register-pop-up__settings {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;

      a {
        color: var(--header-nav-color);
      }

      p {
        color: var(--text-2) !important;
      }

      .register-pop-up__checkbox-setting {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;

        p {
          margin: 0px;
          user-select: none;
          text-transform: lowercase;
        }

        img {
          margin-right: 0;
        }
      }

      p {
        font-size: 14px;
      }

      .register-pop-up__setting-header {
        font-size: 16px;
        margin-bottom: 10px;
        font-weight: 600;
      }

      .register-pop-up__plan {
        user-select: none;

        div {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 10px;
          cursor: pointer;

          p {
            margin: 0;
          }

          input[type="checkbox"] {
            width: 18px;
            height: 18px;
            accent-color: var(--accent-dark);
            cursor: pointer;
          }
        }
      }
    }

    .register-pop-up__fields {
      margin: 15px 0px 15px 0px;
      flex: 1;

      p {
        color: var(--text-2);
      }

      .register-pop-up__field-name {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 0;
      }

      .register-pop-up__list-element {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        position: relative;
        gap: 20px;

        img {
          margin-top: 25px;
          position: absolute;
          right: 20px;
          cursor: pointer;
        }

        p {
          font-size: 14px;
          margin: 2px 0px;
        }

        input {
          border: 0px;
          outline: none;
          height: 44px;
          border-radius: 8px;
          border: 1px solid var(--stroke);
          background: var(--background-1);
          padding: 10px 16px;
          font-family: Montserrat;
          font-size: 14px;
          transition: all var(--transition-duration);
          caret-color: var(--cursor-color);
          color: var(--text-2);

          &::placeholder {
            font-size: 12px;
            color: var(--text-2);
            opacity: 0.5;
            font-family: Montserrat;
          }
        }
      }

      .register-pop-up__field-header {
        margin-top: 15px;
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--background-2);
        padding-bottom: 5px;
      }
    }

    &.mobile-register-modal {
      display: flex;
      flex-direction: column;
      padding: 0 40px;

      .register-mobile__main-text {
        font-size: 20px;
        color: var(--text-2);
      }

      .register-mobile__secondary-text {
        font-size: 14px;
        color: var(--text-4);
      }

      .register-mobile__header {
        margin: 20px 0;
        display: flex;
        justify-content: space-between;
      }

      .register-mobile__icon {
        width: 100%;
        max-width: 400px;
        align-self: center;
      }

      .got-it__button {
        align-self: center;
        position: absolute;
        bottom: 20px;
      }
    }
  }
}

.register-form__row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.register-form__inputs {
  .input-form-component {
    margin: 10px 0px 0px 0px !important;
  }
}

.register-go-back {
  margin-top: 16px;
  position: absolute;
  left: 50px;
  top: 80px;
  font-size: 14px;
  cursor: pointer;
  color: var(--link-color);
  text-decoration: unset !important;
  transition: color var(--transition-duration);
}

.register-privacy-policy-text {
  margin-top: 34px;
  color: var(--text-2);
  transition: all var(--transition-duration);

  a {
    color: var(--link-color);
    text-decoration: unset !important;
    margin-top: 16px;
  }
}

.password-rules__container {
  margin-top: 16px;
  font-size: 14px;

  img {
    width: 18px;
    height: 18px;
  }

  p {
    text-align: left;
    margin: 0 3px;
  }
  ul {
    margin: 0;
    li {
      text-align: left;
      margin: 4px 0;
      p {
        margin: 0;
      }
    }
  }
  .password-rule {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-bottom: 8px;

    p {
      transition: color var(--transition-duration);

      &.success-rule {
        color: var(--success);
      }
      &.error-rule {
        color: var(--error);
      }
    }
  }
}
