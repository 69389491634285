//@import '~@mui/material/Switch';

:root[data-theme="light"] {
  --header-menu__item-text: #4c54a0;
}

:root[data-theme="dark"] {
  --header-menu__item-text: #fff;
}

.header-menu {
  .MuiModal-backdrop {
    background: none !important;
  }

  .MuiMenu-paper {
    margin-top: 9px;
    width: 200px;
    background: var(--background-1);
    border: 1px solid var(--stroke);
    border-radius: 10px;
    box-shadow: unset;
    overflow: visible;
  }

  a {
    text-decoration: none;
  }

  p {
    margin: 0;
  }

  img {
    width: 18px;
    height: 18px;
  }
}

.help-menu {
  .MuiMenu-paper {
    margin-top: 0;
    width: fit-content;
  }

  a {
    &:first-child {
      .header-menu__item-content {
        border-radius: 10px 10px 0px 0px;
      }
    }

    &:last-child {
      .header-menu__item-content {
        border-radius: 0px 0px 10px 10px;
      }
    }
  }
  //a:hover {
  //  img {
  //    filter: invert(35%) sepia(100%) saturate(7500%) hue-rotate(345deg);
  //  }
  //}
}

.header-menu__section {
  border-bottom: 1px solid var(--stroke);
}

.header-menu__item-content {
  position: relative;
  cursor: pointer;
  height: 38px;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--text-2);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;

  .header-menu__item-content__whats-new-dot {
    display: none;
    height: 8px;
    width: 8px;
    background-color: var(--header-menu__item-text);
    border-radius: 50%;
    position: absolute;
    right: 10px;
  }

  &:hover {
    color: var(--header-menu__item-text);
    background: var(--background-2);

    img {
      filter: var(--header-icon-filter);
    }
  }

  &[data-active="true"] {
    color: var(--header-menu__item-text);

    img {
      filter: var(--header-icon-filter);
    }

    .header-menu__item-content__whats-new-dot {
      display: block !important;
    }
  }
}

.dashboard__export-menu {
  .header-menu__item-content {
    &:first-child {
      border-radius: 10px 10px 0px 0px;
    }
    &:last-child {
      border-radius: 0px 0px 10px 10px;
    }
  }
}

.toggle-dark-mode {
  cursor: default;
  justify-content: space-between;
  border-bottom: 1px solid var(--stroke);

  .icon-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.MuiSwitch-root {
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  cursor: pointer;

  .MuiSwitch-switchBase {
    top: 5px;
    left: 4px;
  }

  &.Mui--disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .MuiSwitch-track {
    background: var(--switch-bg-disabled);
    border-radius: 10px;
    display: block;
    height: 20px;
    width: 40px;
    position: absolute;
  }

  & .MuiSwitch-thumb {
    display: block;
    width: 16px;
    height: 16px;
    //top: 2px;
    //left: 2px;
    border-radius: 16px;
    background-color: var(--switch-thumb);
    position: relative;
    transition: all 200ms ease;
  }

  &.MuiSwitch--focusVisible .MuiSwitch-thumb {
    background: var(--link-color);
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  .Mui-checked {
    & + .MuiSwitch-thumb {
      left: 22px;
      top: 5px;
      background-color: var(--switch-thumb) !important;
    }

    & + .MuiSwitch-track {
      opacity: 1 !important;
      background-color: var(--header-nav-color) !important;
    }
  }

  & .MuiSwitch-input {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
}
