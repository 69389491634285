.collapsed_arrows {
  cursor: pointer;
}

.expanded_arrows {
  transform: rotate(180deg);
  cursor: pointer;
}

.toggle_projects-icon {
  display: flex;
  margin-right: 130px;
}

.toggle_users-icon {
  display: flex;
  margin-right: 160px;
}

.capacity__control-bar {
  position: absolute;
  top: 60px;
  width: 100%;
  max-width: 100%;
  height: 60px;
  padding: 9px 30px;
  display: flex;
  justify-content: space-between;
}

.capacity__control-bar__splitter {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.control-bar__left-side {
  display: flex;
  gap: 40px;
  flex: 1;
}

.capacity-settings {
  width: 490px;
  height: 165px !important;

  scrollbar-gutter: unset !important;
  // padding: 0px 20px !important  ;
}

.overflow-scrolled {
  overflow-y: scroll !important;
}

.capacity__component {
  display: flex;
  flex-direction: column;

  .users-block__headline,
  .project-row {
    min-width: fit-content;
  }
}

.capacity__component {
  position: fixed;
  top: 120px;
  left: 30px;
  bottom: 0;
  right: 30px;
  padding-right: 7px;
  padding-bottom: 20px;

  overflow-x: scroll;
  overflow-y: auto;
  background-color: var(--background-1);

  input {
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.yellow-bg {
  background-color: var(--teambook-yellow) !important;

  p {
    color: var(--text-14) !important;
  }
}

.red-bg {
  background-color: var(--teambook-red) !important;

  p {
    color: var(--text-14) !important;
  }
}

.grey-bg {
  background-color: var(--background-2) !important;

  p {
    color: var(--text-2) !important;
  }
}

.black-bg {
  background-color: var(--background-1) !important;

  p {
    color: var(--text-2) !important;
  }
}

.users-block__headline {
  display: flex;
  width: 100%;
  height: 64px;
  color: var(--text-2);

  div {
    background-color: var(--background-2);
  }

  .table-cell {
    padding: 16px 0;
  }
}

.capacity-planning {
  .table-cell {
    position: relative;
  }
}

.table-cell {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: var(--text-2);
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
}

.projects-block__headline {
  display: flex;
  width: 100%;
  height: 56px;

  div {
    background-color: var(--background-1);
  }

  .table-cell {
    // padding: 13px 0;

    input {
      color: var(--text-4);
      font-size: 14px;
      font-style: italic;
    }
  }
}

.projects-block__headline-actions {
  display: flex;
  align-items: center;
  margin-right: 17px;
  gap: 10px;

  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.reporting__headline {
  display: flex;
  border-bottom: 1px solid var(--stroke);
}

.reporting__headline__name {
  min-width: 400px;
  max-width: 400px;
  position: sticky;
  left: 0;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--background-1);

  .named-row {
    max-width: 210px;
    min-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .named-row-project {
    max-width: 180px;
    min-width: 180px;
    display: flex;
    align-items: center;

    gap: 10px;
  }

  p {
    text-align: left;
    margin: 0px;
    padding: unset !important;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: 200px;
    color: var(--text-2);
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 16px;
    display: flex;
    align-items: center;
  }

  .duration {
    width: 150px !important;
    max-width: 150px;
    min-width: 150px;
    text-align: center;
    font-style: unset;
    justify-content: center;
  }
}

.tags-separator {
  .table-cell {
    background: var(--background-1);
    border: unset;
  }

  .users-block__headline {
    background: var(--background-1);
  }

  .users-block__headline__user-name {
    width: 400px;
    max-width: 400px;
    min-width: 400px;
    background: var(--background-1);
    justify-content: unset;
    margin: unset;
    border-right: 1px solid var(--stroke);
    border-left: 1px solid var(--stroke);

    p {
      font-weight: 500;
      text-align: center;
    }
  }
}

.reporting-block {
  .project-row {
    width: 100%;
  }
}

.reporting-tags {
  .reporting__headline {
    position: relative;
    width: fit-content;
    min-width: 100%;
  }
}

.users-block-main__headline__user-name {
  min-width: 260px;
  max-width: 260px;
  padding: 5px 0;
  position: sticky;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  border-left: 1px solid var(--stroke);
  border-right: 1px solid var(--stroke);
  z-index: 3;

  .users-name-block {
    max-width: 196px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  p {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    margin: 0px;
    line-height: 120%;
  }

  p.light {
    color: white;
  }

  p.dark {
    color: var(--text-2);
  }

  .add-user {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}

.users-block__headline__user-name {
  min-width: 260px;
  max-width: 260px;
  padding: 5px 0;
  position: sticky;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  background-color: var(--background-2);
  border-right: 1px solid var(--stroke);
  border-left: 1px solid var(--stroke);
  border-bottom: 1px solid var(--stroke);
  z-index: 3;

  .flex {
    margin-top: 2px;
    display: flex;
    align-items: center;
  }

  p {
    color: var(--text-2);
    text-align: start;
    margin: 0px;
    width: 96px;
    font-size: 14px;

    line-height: 24px;
    font-weight: 400;
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  p.light {
    color: white;
  }

  p.dark {
    color: var(--text-2);
  }

  .add-user {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}

.users-block__project-info-main {
  min-width: 260px;
  max-width: 260px;
  border-bottom: 1px solid var(--stroke);
  border-left: 1px solid var(--stroke);
  border-right: 1px solid var(--stroke);
  height: 51px;
  position: sticky;
  left: 0;
  background-color: var(--background-1);
  display: flex;
  justify-content: space-between;
  z-index: 4;

  p {
    margin: 8px 0 0 10px;
    text-align: start;
    color: var(--accent-dark);
    cursor: pointer;
    text-decoration: underline;
  }

  .arrow-name {
    width: 90%;
    align-items: center;
    padding: 5px 0;

    p {
      font-style: italic;
      max-width: 180px;
      margin: 0px 5px;
      font-size: 14px;
      color: var(--text-12);
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
    }
  }

  img {
    width: 25px;
  }
}

.users-block__project-info p {
  margin: 0 0 0 30px;
  margin-right: 25px;
  text-align: start;
}

.users-block__project-info div {
  padding-top: 6px;
}

.users-block__projects-capacity {
  padding-left: 30px;

  .avatar {
    padding: 0px !important;
  }

  p {
    color: var(--text-12) !important;
    font-weight: 400 !important;
    font-size: 16px;
    margin-left: 10px;
    min-width: 140px;
    max-width: 140px;
  }
}

.users-block__project-info {
  min-width: 260px;
  max-width: 260px;
  border-bottom: 1px solid var(--stroke);
  border-left: 1px solid var(--stroke);
  border-right: 1px solid var(--stroke);
  height: 51px;
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: var(--background-1);
  display: flex;
  align-items: center;

  img {
    width: 25px;
  }

  p {
    color: var(--text-2);
    padding: unset !important;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    width: fit-content;
    margin-right: 25px;
    font-size: 14px;
  }

  .user-name {
    margin-right: 20px;
    text-decoration: underline;
    text-underline-offset: 2px !important;
    color: var(--text-12);
    cursor: pointer;
  }

  .arrow-switch {
    width: 24px;
  }
}

.greyed {
  background-color: var(--capacity-inner-content);
}

.spread-dialog {
  .MuiDialogContent-root {
    height: 350px;
  }

  .MuiPaper-root {
    width: 50%;
  }

  .MuiDialogTitle-root p {
    margin: unset;
  }
}

.left-side,
.right-side {
  height: 43px;
  display: flex;
  align-items: center;
}

.users-block {
  position: relative;
}

.users-block__dates-row {
  display: flex;
  align-items: center;
  height: 34px;
  position: sticky;
  top: 0;

  background-color: var(--background-1);
  z-index: 11;

  width: fit-content;
  min-width: 100%;

  .left-side {
    background-color: var(--background-1);
    min-width: 260px;
    max-width: 260px;
  }

  .left-side.second-row {
    border-top: 1px solid var(--stroke);
    border-left: 1px solid var(--stroke);
    border-bottom: 1px solid var(--stroke);
  }

  &.is-capacity-months {
    height: 36px;
  }

  .table-cell {
    padding: 13px 0;

    p {
      font-size: 14px;
    }
  }

  .left-side {
    min-width: 260px;
    max-width: 260px;
    display: flex;
    z-index: 2;
    position: sticky;
    left: 0;
  }
}

.add-projects-dialog {
  .MuiPaper-root {
    width: 50%;
  }

  .MuiDialogContent-root {
    height: 250px;
  }
}

.copy-planner-dialog {
  .MuiPaper-root {
    width: 50%;
    height: 600px;
  }

  .MuiDialogContent-root {
    height: 300px;
  }
}

.copy-planner-select {
  width: 100%;
}

.copy-planner__setting {
  display: flex;
  gap: 10px;
}

.copy-planner-subblock {
  margin-bottom: 20px;

  p {
    margin-bottom: 0;
  }

  .date-selector {
    margin-top: 3px;
  }

  .copy-planner__setting {
    p {
      cursor: pointer;
    }
  }
}

.copy-planner-subblock > div {
  margin-top: 6px;
}

.table-cell {
  min-width: 80px;
  flex-basis: 0;
  flex-grow: 1;
  border-bottom: 1px solid var(--stroke);

  p {
    font-size: 14px;
    margin: unset;
  }

  input {
    height: 100%;
    margin: unset;
    padding: unset;
    font-family: Montserrat;
    color: var(--text-2);
    background-color: var(--background-1);
    outline: none !important;
    border: none;
    font-size: 14px;
    // border-left: 1px solid var(--stroke);
  }

  input:focus {
    outline: none !important;
    border: 1px solid var(--text-2) !important;
  }

  &.current-month {
    background-color: var(--stroke);

    p {
      color: var(--button-text-hover);
    }
  }
}

.capacity-planning {
  .planner-type-select,
  .planner-type-select__shown-component {
    margin: 0 !important;
  }

  .date-picker-container {
    border: 1px solid var(--stroke);
    border-radius: 7px;
    cursor: pointer;

    .arrow-block {
      padding: 0 10px;
      height: 38px;
      cursor: pointer;
      justify-content: center;
    }

    input {
      text-align: center;
      color: var(--button-outline-text-default) !important;
      font-family: "Montserrat";
      font-size: 14px;
      cursor: pointer !important;
    }
  }

  .dashboard__tb-button {
    margin-top: auto;
    margin-bottom: auto;
    padding: 8px !important;

    img {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.date-row__year-row {
  .table-cell {
    border-right: 1px solid var(--stroke);
    border-top: 1px solid var(--stroke);
  }
  border-top-right-radius: 7px;
}

.users-block__dates-row.first-row {
  .left-side,
  .right-side {
    height: 34px !important;
  }

  .left-side::before {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 7px 0px 0px 0px;
    border-left: 1px solid var(--stroke);
    border-top: 1px solid var(--stroke);
  }

  .left-side {
    z-index: 11;
    background: var(--background-1);
  }
}

.date-row__year-row {
  position: absolute;
  left: 0px;
  height: 34px;
  z-index: 10;
  width: 100%;
  min-width: fit-content;
  display: flex;

  .table-cell {
    border-bottom: unset;
    justify-content: unset;
  }
}

.capacity__year-separator {
  padding: 0px;

  .date-row__year {
    padding: 0px;
  }
}

.date-row__year {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // right: 0px;
  top: 0px;

  p {
    margin: auto;
    height: fit-content;
    position: sticky;
    left: 399px;
    right: 15px;
    color: var(--text-9);
    font-size: 12px;
    letter-spacing: 0.4px;
  }
}

.users-block__add-project {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: white;
}

.capacity__control-bar {
  .tb-react-select__single-value {
    color: #4c54a0;
  }
}

.capacity-planning {
  .project-list__project-color {
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    box-shadow: inset 4px 4px 14px rgba(255, 255, 255, 0.25);
    border-radius: 4px;
    margin: 0px 10px 0px 30px;
  }
}

.dashboard {
  .project-list__project-color {
    min-width: 10px;
    max-width: 10px;
    height: 24px;
    box-shadow: inset 4px 4px 14px rgba(255, 255, 255, 0.25);
    border-radius: 4px;
    margin: 0px;
  }
}

.reporting-tags,
.dashboard {
  .reporting-block,
  .users-block {
    min-width: 100%;
    width: fit-content;
  }
}

.reporting-tags__total {
  width: 90px;
  height: 60px;
  position: absolute;
  z-index: 999;
  right: 0px;
  top: 170px;
  background: #fff;
  border-left: 1px solid var(--stroke);
  border-bottom: 1px solid var(--stroke);
  border-top: 1px solid var(--stroke);

  p {
    font-size: 14px;
    margin: 0px;
  }
}

.table-cell__content {
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.border-right {
    height: 100%;
    border-right: 1px solid var(--stroke) !important;
  }
}

.MuiInputBase-root {
  color: var(--text-2) !important;
  border-bottom: none !important;

  &:before {
    border-bottom: 1px solid var(--text-2) !important;
  }

  &:after {
    border-bottom: 1px solid var(--accent-dark) !important;
  }

  &:hover {
    &:before,
    &:after {
      border-bottom: 1px solid var(--text-2) !important;
    }
  }
}

.capacity-planning {
  input {
    -webkit-user-select: auto;
    user-select: auto;
    -webkit-user-drag: none;
  }
}

.planned-days {
  justify-content: space-between;
}

.right-side {
  min-width: fit-content;
  width: 100%;
}
