.projection__header,
.projection__control-row {
  width: 100%;
  padding: 0 30px;
  display: flex;
  align-items: center;
  height: 60px;
  border-bottom: 1px solid var(--stroke);
  position: fixed;
  top: 0;
  z-index: 3;
  background: var(--background-1);
}

.projection__control-row {
  top: 60px;
}

.projection__logo {
  display: flex;
  align-items: end;
  gap: 10px;
  height: 25px;

  img:last-child {
    height: 20px;
  }
}

.projection__header-title {
  display: flex;
  align-items: center;
  height: 28px;
  gap: 20px;
  margin: 0 auto;
  padding-right: 180px;

  p {
    color: var(--text-2);
    font-size: 20px;
    font-weight: 500;
    line-height: 132%;
    margin: 0;
  }

  @media (max-width: 1100px) {
    padding: 0;
  }
}

.projection__control-row {
  gap: 20px;

  .arrow-block {
    padding: 0 5px;
    height: 38px;
    gap: 5px;
  }

  .planner-control__date-picker {
    width: 72px;
  }

  .projection__today-button {
    width: 44px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 7px;
    border: 1px solid var(--stroke);
  }

  .MuiInputBase-input {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.4px;
  }
}

.projection__table-frame {
  position: relative;
  height: fit-content;
}

.projection__table {
  width: fit-content;
  min-height: 0;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
}

.projection__months-row {
  min-height: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--stroke);
  width: fit-content;
  position: sticky;
  top: 120px;
  background: var(--background-1);
  z-index: 5;
}

.projection__hide {
  width: 180px;
  min-height: 30px;
  height: 30px;
  margin-bottom: -30px;
  background: var(--background-1);
  position: sticky;
  left: 0;
  z-index: 4;
  border-right: 1px solid var(--stroke);
  border-bottom: 1px solid var(--stroke);
  top: 164px;
}

.projection__weeks-row {
  height: 30px;
  display: flex;
  border-bottom: 1px solid var(--stroke);
  width: fit-content;
  position: sticky;
  top: 164px;
  background: var(--background-1);
  z-index: 3;
}

.projection__month-column {
  width: 60px;

  &.divider {
    border-right: 1px solid var(--stroke);
    height: 100%;
  }

  p {
    color: var(--button-outline-text-default);
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.4px;
    text-transform: capitalize;
    margin: auto 20px;
  }
}

.projection__week-column {
  max-width: 420px;
  width: 420px;
  border-left: 1px solid var(--planner-divider);
  border-right: 1px solid var(--stroke);
  display: flex;
}

.projection__day-column {
  min-width: 60px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-3);
  font-size: 11px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;

  &.weekends {
    color: var(--accent-light);
  }

  &.less-today {
    opacity: 0.5;
  }

  &.today {
    min-width: 61px;
    margin-left: -1px;
    color: var(--button-text-hover);
    background: var(--stroke);
    border: 1px solid var(--stroke);
    opacity: 1 !important;
  }
}

.projection__user-row {
  position: relative;
  width: fit-content;
  height: 80px;
  border-bottom: 1px solid var(--stroke);

  &.first-row {
    height: 81px;
    border-top: 1px solid var(--stroke);
  }
}

.projection__project-section {
  width: fit-content;
}

.projection__user-column,
.projection__project-column {
  width: 180px;
  min-width: 180px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-1);
  border-right: 1px solid var(--stroke);
  border-bottom: 1px solid var(--stroke);
  position: sticky;
  left: 0;
  z-index: 2;
  color: var(--text-2);

  p {
    margin: 0;
  }
}

.projection__project-column {
  border-bottom: none;
  padding: 10px;
  color: var(--background-1);
}

.projection__weeks-container {
  position: relative;
  display: flex;
  margin-top: -80px;
}

.projection__day {
  min-width: 60px;
  height: 80px;
  border-right: 1px solid var(--stroke);
  overflow: hidden;
  // z-index: -1;

  &.weekends {
    background: var(--background-2);
  }
}

.projection__booking {
  width: 59px;
  display: flex;
}

.projection__booking-line {
  width: 3px;
  height: 100%;
}

.projection__booking-content {
  width: 100%;
  height: 100%;
}

.projection__booking-title {
  padding: 2px;
  display: flex;
  justify-content: space-between;
}

.projection__booking-code,
.projection__booking-time {
  margin: 0;
  font-size: 10px;
  line-height: 120%;
  text-overflow: ellipsis;
}

.projection__booking-time {
  margin-left: auto;
}

.projection__teambook-ad-frame {
  padding: 60px 0;
}

.projection__teambook-ad {
  max-width: 510px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--stroke);
  margin-top: 145px;
  margin-bottom: 80px;
  position: sticky;
  top: 0;
  left: 38%;

  img {
    width: 200px;
    height: 134px;
  }

  @media (max-width: 1100px) {
    display: block;
    width: fit-content;
    flex-wrap: wrap;
  }
}

.projection__teambook-ad-content {
  width: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: start;
  gap: 10px;

  .blue-text {
    color: var(--header-nav-color);
    font-weight: 500;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 150%;
    font-weight: 300;

    &:nth-child(2) {
      color: var(--text-2);
    }
  }
}

.projection__teambook-ad-button {
  width: 260px;
  height: 40px;
}

.projection-header__circle {
  border-radius: 15px;
  height: 15px;
  width: 15px;
}
